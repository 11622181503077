import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'router'

interface CompanyNotFoundProps {
    setTitle: (param: string) => void
    navigateHandler: (props: string) => void
}

export const CompanyNotFoundPage = ({ setTitle, navigateHandler }: CompanyNotFoundProps) => {
    useEffect(() => {
        setTitle('We couldn’t find your company')
    }, [])

    return (
        <>
            <div className="sign-up__row">
                <p className="sign-up__text" style={{ margin: 0 }}>
                    Please contact your company’s portal admin to finish creating your account or{' '}
                    <Link style={{ textDecoration: 'underline' }} className="link" to={`/${ROUTES.company.base}`}>
                        Register your Company
                    </Link>
                </p>
            </div>
        </>
    )
}
