import React, { useEffect, useState } from 'react'
import { ReactComponent as ArrowLeftIcon } from '../assets/icons/arrow-left.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import OlimpLogo from '../assets/images/olimp-logo.png'
import OlimpLogo2 from '../assets/images/OLIMP_logo_secondary.png'
import CarouselImage from '../assets/images/sign-up-carousel.jpg'
import CarouselImage1 from '../assets/images/sign-up-carousel1.jpg'
import { SignUpBanner } from './SignUpBanner'
import { ROUTES } from 'router'
import { URLs } from '../urls'
import { Company, LocalStorageNames } from 'types'
import ConditionalImage from './ConditionalImage'
import { useCompany } from 'store/context/CompanyContext'
import { useMicrosite } from '../api/useMicrosite'

interface SignUpWrapProps {
    children: React.ReactNode
    onBack?: (props: string) => void
    title?: string
    progress?: number
    showProgress: boolean
    showBackButton?: boolean
}

export const SignUpWrap = ({
    onBack,
    title,
    progress,
    showProgress,
    showBackButton = true,
    children,
}: SignUpWrapProps) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [currentItem, setCurrentItem] = useState(1)
    const [companyData, setCompanyData] = useState<Company | null>(null)
    const intervalTime = 5000
    const showCompanyLogoRoutes = [
        ROUTES.login,
        ROUTES.existingCompany.yourInformation,
        ROUTES.existingCompany.verification,
    ]
    const { data: micrositeData, slug } = useMicrosite()
    const isVerifyPage = location.pathname.includes(ROUTES['verify-email'])
    const isInvitePage = location.pathname.includes(ROUTES.invite)
    const [shouldReset, setShouldReset] = useState(false)
    const { company } = useCompany()

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCurrentItem((prevItem) => (prevItem + 1) % carouselItems.length)
    //     }, intervalTime)

    //     return () => clearInterval(interval)
    // }, [])

    useEffect(() => {
        if (company) {
            setCompanyData(company)
        }
    }, [company])
    const setCompanyInformation = () => {
        setShouldReset(true)

        if (micrositeData) {
            const cmpData = {
                id: micrositeData.accountId,
                name: micrositeData.accountName,
                type: 'Demand',
                existingId: micrositeData.accountId,
            } as any

            localStorage.setItem(LocalStorageNames.companyInfo, JSON.stringify(cmpData))
        } else if (slug && !micrositeData) {
            localStorage.removeItem(LocalStorageNames.companyInfo)
        }

        if (slug) {
            localStorage.setItem(LocalStorageNames.siteSlug, slug)
            localStorage.setItem(LocalStorageNames.role, 'demand')
        } else if (location.pathname === '/login' || location.pathname === '/') {
            localStorage.removeItem(LocalStorageNames.companyInfo)
            localStorage.removeItem(LocalStorageNames.role)
            localStorage.removeItem(LocalStorageNames.siteSlug)
        }
        const getCompany = localStorage.getItem(LocalStorageNames.companyInfo)
        const parseCompanyData = JSON.parse(getCompany!) as Company
        if (parseCompanyData) {
            setCompanyData(parseCompanyData)
        }
    }

    useEffect(() => {
        const cmp = localStorage.getItem(LocalStorageNames.companyInfo)

        if (cmp || slug) {
            setCompanyInformation()
        }
    }, [location.pathname, slug, micrositeData])

    useEffect(() => {
        if (location.pathname === '/' && companyData?.id) {
            setCompanyInformation()
            navigate(ROUTES.existingCompany.yourInformation)
        }
    }, [location.pathname, slug, micrositeData, companyData])

    const carouselItems = [
        {
            image: CarouselImage,
            title: 'Carbon Reduction Right Now',
            text: 'OLIMP Warehousing has engineered a transportation solution to reduce external fuel costs and deliver ready-to-scale, eco-friendly options to return your fleet to the road faster.',
        },
        {
            image: CarouselImage1,
            title: 'On-Demand Warehousing Now',
            text: 'Whether you need a warehouse or have a warehouse - OLIMP is here to connect companies needing cross-docks, pallet reworks, long and short term warehousing, transloading and drayage with warehouses.',
        },
    ]

    return (
        <div>
            {isVerifyPage ? (
                <div>{children} </div>
            ) : (
                <div className="sign-up__wrap">
                    <div className="sign-up__leftSide">
                        <div>
                            {isInvitePage ? null : (
                                <>
                                    <div className="sign-up__header">
                                        {/* show company logo */}
                                        {companyData ? (
                                            <>
                                                <div className="sign-up__logo2">
                                                    <img src={OlimpLogo2} className="sign-up__logo" alt="Olimp logo" />
                                                </div>
                                                <ConditionalImage
                                                    src={`${URLs.baseApi}/accounts/${companyData?.id}/logo`}
                                                    resetTrigger={{ shouldReset, setShouldReset }}
                                                    fallbackText={companyData?.name}
                                                />
                                            </>
                                        ) : (
                                            <img src={OlimpLogo} className="sign-up__logo" alt="Olimp logo" />
                                        )}
                                    </div>

                                    {progress && showProgress && (
                                        <div className="progress">
                                            <div className="progress-fill" style={{ width: `${progress || 0}%` }} />
                                        </div>
                                    )}

                                    {onBack && showBackButton && (
                                        <div className="sign-up__back" role="button" tabIndex={-1}>
                                            <div
                                                className="sign-up__backTitle"
                                                onClick={() => {
                                                    navigate(-1)
                                                }}
                                            >
                                                <ArrowLeftIcon /> <span className="sign-up__backButtonText">Back</span>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {title && <h2 className="sign-up__title">{title}</h2>}
                            {children}
                        </div>
                    </div>

                    <div className="sign-up__rightSide">
                        <SignUpBanner carouselItems={carouselItems} currentItem={currentItem} />
                    </div>
                </div>
            )}
        </div>
    )
}
