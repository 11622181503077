import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from 'components/UI/Loader'
import { ROUTES } from 'router'
import { InviteLinkDataType, LocalStorageNames } from 'types'
import { toast } from 'react-toastify'
import { URLs } from 'urls'

interface InvitePageProps {
    setTitle: (param: string) => void
    setShowProgress: (param: boolean) => void
    navigateHandler: (props: string) => void
    setShowBackButton?: any
}

export const InvitePage = ({ setTitle, setShowProgress, navigateHandler, setShowBackButton }: InvitePageProps) => {
    const { id } = useParams()
    const [message, setMessage] = useState("Hang tight! We're checking your invitation details.")
    const [isError, setIsError] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTitle('')
        setShowBackButton(false)
        setShowProgress(false)
    }, [])

    useEffect(() => {
        let timer
        localStorage.clear()
        if (id) {
            localStorage.setItem(LocalStorageNames.inviteLink, id)
            timer = setTimeout(() => {
                fetchInviteLink()
            }, 1500)
        }
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [id])

    const fetchInviteLink = async () => {
        try {
            const response = await fetch(`${URLs.baseApi}/accounts/invites/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const data: InviteLinkDataType = await response.json()

            if (response.ok) {
                // console.log(data)
                // @ts-ignore
                navigateHandler(ROUTES.existingCompany.yourInformation)
            }

            if (!response.ok) {
                console.log(data)
                // @ts-ignore
                setMessage(data?.message)
                // @ts-ignore
                toast(data?.message ?? 'Something went wrong', { type: 'error' })
                setLoading(false)
                setIsError(true)
            }
        } catch (error: any) {
            toast(error?.message ?? 'Something went wrong', { type: 'error' })
            console.log(error)
            setLoading(false)
        }
    }

    return (
        <div className={'sign-up__row sign-up__verification_center'}>
            <div>
                <p
                    className={`sign-up__verification__text ${isError && 'sign-up__verification_error'} `}
                    style={{ textAlign: 'center' }}
                >
                    {message}
                </p>
                {loading && <Loader type="medium" className="sign-up__loader_mb" />}
            </div>
        </div>
    )
}
