import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Button from '../components/UI/Button'
import InputLabel from '../components/UI/InputLabel'
import GoogleAutocompleteInput from 'components/UI/GoogleAutocompleteInput'
import { ROUTES } from '../router'
import { URLs } from '../urls'
import { LocalStorageNames } from 'types'
import { toast } from 'react-toastify'

interface CarrierPageProps {
    setTitle: (param: string) => void
    navigateHandler: (props: string) => void
}

const carrierValidationSchema = Yup.object().shape({
    companyName: Yup.string().required('Company name is required'),
    companyLocation: Yup.object()
        .shape({
            formattedAddress: Yup.string().required('Valid address is required'),
        })
        .required('Location field is required'),
})

export const RegisterCompany = ({ setTitle, navigateHandler }: CarrierPageProps) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const currentRole = localStorage.getItem(LocalStorageNames.role)
    const currentSlug = localStorage.getItem(LocalStorageNames.siteSlug)
    useEffect(() => {
        setTitle('Register Your Company')
        localStorage.clear()

        if (currentRole && currentSlug) {
            localStorage.setItem(LocalStorageNames.role, currentRole)
            localStorage.setItem(LocalStorageNames.siteSlug, currentSlug)
        }
    }, [])

    const formik = useFormik({
        initialValues: {
            companyName: '',
            companyLocation: '',
        },

        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            setErrorMessage(null)
            try {
                const response = await fetch(`${URLs.baseApi}/accounts/validate?name=${values.companyName}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })

                // check if response is ok
                if (!response.ok) {
                    const data = await response.json()

                    console.error(data.message)
                    setErrorMessage(data.message)
                    toast(data.message, { type: 'error' })
                } else {
                    // const data = await response.json()
                    // console.log(data)

                    // save companyName + companyLocation in local storage
                    localStorage.setItem(LocalStorageNames.companyName, JSON.stringify(values.companyName))
                    localStorage.setItem(LocalStorageNames.companyLocation, JSON.stringify(values.companyLocation))

                    if (currentRole) {
                        navigateHandler(ROUTES.company.yourInformation)
                    } else {
                        // go to next page
                        navigateHandler(ROUTES.company.selectionPage)
                    }
                }
            } catch (error: any) {
                console.error(error)
                setErrorMessage(error?.message)
                toast(error?.message, { type: 'error' })
            } finally {
                setSubmitting(false)
            }
        },
        validationSchema: carrierValidationSchema,
        validateOnChange: false,
    })

    const handleInputChange = (e: any) => {
        setErrorMessage(null)
        formik.handleChange(e)
        formik.setErrors({})
    }

    return (
        <>
            <div className="sign-up__row">
                <InputLabel
                    name="companyName"
                    className="sign-up__input"
                    value={formik.values.companyName}
                    errorMessage={formik.errors.companyName}
                    onChange={handleInputChange}
                    label="Company name*"
                />
            </div>
            <div className="sign-up__row">
                <GoogleAutocompleteInput
                    //  @ts-ignore
                    errorMessage={formik.errors.companyLocation?.formattedAddress || formik.errors.companyLocation}
                    className="sign-up__googleInput"
                    type="text"
                    id="addressSearch"
                    name="companyLocation"
                    data-name="companyLocation"
                    wrapperClassName={'sign-up__location'}
                    changePlace={(name, val) => {
                        formik.setFieldValue('companyLocation', {})
                    }}
                    inputPath="address"
                    label="Company Location*"
                    changePlaceFull={(field, value) => {
                        // console.log('changePlaceFull', { field, value })
                        if (value?.formattedAddress) {
                            formik.setFieldValue('companyLocation', value)
                            formik.setErrors({})
                        } else {
                            formik.setFieldValue('companyLocation', { formattedAddress: '' })
                        }
                    }}
                    placeholder=""
                    onBlur={() => {}}
                />
            </div>
            {errorMessage && (
                <p className="input-label__error" style={{ marginTop: 0, marginBottom: 20 }}>
                    {errorMessage}
                </p>
            )}
            <Button
                disabled={!formik.isValid}
                className="button"
                type="button"
                types={['blue', 'full']}
                loading={formik.isSubmitting}
                onClick={() => formik.handleSubmit()}
            >
                Next
            </Button>
        </>
    )
}
