import { useEffect } from 'react'
import * as Yup from 'yup'
import InputLabel from '../components/UI/InputLabel'
import { useFormik } from 'formik'
import Button from '../components/UI/Button'
import { ROUTES } from 'router'
import { URLs } from '../urls'
import { Company, LocalStorageNames } from 'types'
import { toast } from 'react-toastify'

interface FindYourCompanyProps {
    setTitle: (param: string) => void
    navigateHandler: (props: string) => void
}

const companyValidationSchema = Yup.object().shape({
    companyEmail: Yup.string().email('Company email must be a valid email').required('Company email is required field'),
})

export const FindYourCompany = ({ setTitle, navigateHandler }: FindYourCompanyProps) => {
    useEffect(() => {
        setTitle('Find Your Company')
        localStorage.clear()
        // const isNewCompany = location.pathname.includes(ROUTES.existingCompany.base)
    }, [])

    const formik = useFormik({
        initialValues: {
            companyEmail: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            localStorage.setItem(LocalStorageNames.companyEmail, JSON.stringify(values.companyEmail))

            try {
                const response = await fetch(`${URLs.baseApi}/accounts/search?email=${values.companyEmail}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })

                const data: Company = await response.json()

                if (response.ok) {
                    if (data) {
                        // save to localStorage existing companyInfo => data, role and existingId
                        const companyData = {
                            ...data,
                            existingId: data.id,
                        }
                        localStorage.setItem(LocalStorageNames.companyInfo, JSON.stringify(companyData))
                        localStorage.setItem(LocalStorageNames.role, data?.type)
                        navigateHandler(ROUTES.existingCompany.existing)
                    } else {
                        // if company doesn't exist just go to not find company page
                        localStorage.clear()
                        navigateHandler(ROUTES.existingCompany.companyNotFound)
                    }
                }

                if (!response.ok) {
                    // @ts-ignore
                    toast(data?.message ?? 'Something went wrong', { type: 'error' })
                    localStorage.clear()
                }
            } catch (error: any) {
                console.log('Error: ', error)
                // toast(error?.email ?? 'Something went wrong', { type: 'error' })
                localStorage.clear()
                navigateHandler(ROUTES.existingCompany.companyNotFound)
            } finally {
                setSubmitting(false)
            }
        },
        validateOnChange: false,
        validationSchema: companyValidationSchema,
    })

    const handleInputChange = (e: any) => {
        formik.handleChange(e)
        formik.setErrors({})
    }

    return (
        <>
            <div className="sign-up__row">
                <InputLabel
                    name="companyEmail"
                    className="sign-up__input"
                    value={formik.values.companyEmail}
                    errorMessage={formik.errors.companyEmail}
                    onChange={handleInputChange}
                    label="Your Company Email*"
                    type="email"
                />
            </div>
            <Button
                disabled={!formik.isValid || formik.isSubmitting}
                className="button"
                type="button"
                types={['blue', 'full']}
                loading={formik.isSubmitting}
                onClick={() => formik.handleSubmit()}
            >
                Next
            </Button>
        </>
    )
}
