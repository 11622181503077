import { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import Checkbox from '../components/UI/Checkbox'
import InputLabel from '../components/UI/InputLabel'
import { getOLIMPLandingURL } from '../utils/getOLIMPLandingURL'
import { useFormik } from 'formik'
import Button from '../components/UI/Button'
import GoogleAutocompleteInput from './UI/GoogleAutocompleteInput'
import { ROUTES } from 'router'
import { Link, useLocation } from 'react-router-dom'
import { Company, InviteLinkDataType, LocalStorageNames } from 'types'
import { URLs } from '../urls'
import { toast } from 'react-toastify'
import { useCompany } from 'store/context/CompanyContext'
import { Iti } from 'intl-tel-input'
import PhoneInput from './UI/PhoneInput'

export interface ISignUpProps {
    firstName: string
    lastName: string
    email: string
    phone: string
    title: string
    password: string
    repeatPassword: string
    agreement: boolean
    location: string
    url: string
    company?: {}
}

interface ISignUpPageListProps {
    setTitle?: any
    navigateHandler: (props: string) => void
    setShowBackButton?: any
}

const phoneRegExp = /^(\+?\d{1,4}[-\s]?)?(\(?\d{1,3}\)?[-\s]?)?[\d\s-]{7,10}$/

export const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Company email must be a valid email').required('Company email is required field'),
    phone: Yup.string()
        .matches(phoneRegExp, 'Invalid phone number format. Use numbers and optional country code.')
        .min(7, 'Phone number must be at least 7 digits long.')
        .required('Phone number is required'),
    location: Yup.object()
        .shape({
            formattedAddress: Yup.string().required('Valid address is required'),
        })
        .required('Warehouse Location is required'),
    agreement: Yup.boolean().required('Agreement is required').oneOf([true], 'You must accept the agreement'),
    password: Yup.string()
        .required('Password is required')
        .min(7, 'Password must be at least 7 characters long')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[\d\W]/, 'Password must contain at least one number or special character'),
    repeatPassword: Yup.string()
        .required('Please confirm your password')
        .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
})

export const SignUpPageList = ({ setTitle, navigateHandler, setShowBackButton }: ISignUpPageListProps) => {
    const [isEditableEmail, setIsEditableEmail] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const locations = useLocation()
    const existingCompanyPath = locations.pathname.includes(ROUTES.existingCompany.base)
    const role = localStorage.getItem(LocalStorageNames.role)
    const lbInvite = localStorage.getItem(LocalStorageNames.lbInvite)
    const inviteLink = localStorage.getItem(LocalStorageNames.inviteLink)
    const siteSlug = localStorage.getItem(LocalStorageNames.siteSlug)
    const { setCompany, company } = useCompany()

    const mobileItiRef = useRef<Iti | null>(null)

    useEffect(() => {
        setTitle('Your Information')

        const cmp = localStorage.getItem(LocalStorageNames.companyInfo)
        if (cmp) {
            setShowBackButton(false)
        }
    }, [company])

    useEffect(() => {
        // if company just created
        if (!existingCompanyPath) {
            // get name and location
            const isCompanyName = localStorage.getItem(LocalStorageNames.companyName)
            const isCompanyLocation = localStorage.getItem(LocalStorageNames.companyLocation)

            // set in formik body
            if (isCompanyName && isCompanyLocation) {
                const companyName = JSON.parse(isCompanyName)
                const companyLocation = JSON.parse(isCompanyLocation)

                formik.setFieldValue(
                    'company',

                    {
                        newCompanyName: companyName,
                        newCompanyLocation: companyLocation,
                    },
                )
            }
        }
    }, [])

    useEffect(() => {
        const isCompanyExist = localStorage.getItem(LocalStorageNames.companyInfo)
        const companyEmail = localStorage.getItem(LocalStorageNames.companyEmail)

        // if we have email in local storage paste it
        if (companyEmail) {
            formik.setFieldValue('email', JSON.parse(companyEmail))
        }
        if (isCompanyExist) {
            // if company exist
            const existingCompany: Company = JSON.parse(isCompanyExist)
            // fill company
            formik.setFieldValue('company', existingCompany)
            const parseCompanyEmail = JSON.parse(companyEmail!)

            if (parseCompanyEmail) {
                setIsEditableEmail(true)
                formik.setFieldValue('email', parseCompanyEmail)
            } else {
                setIsEditableEmail(false)
            }

            return
        }
    }, [])

    // inviteLink fetch
    useEffect(() => {
        if (inviteLink) {
            fetchInviteLink()
        }
    }, [])

    const fetchInviteLink = async () => {
        try {
            const response = await fetch(`${URLs.baseApi}/accounts/invites/${inviteLink}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const data: InviteLinkDataType = await response.json()

            if (response.ok) {
                // console.log(data)
                // @ts-ignore
                setCompany({
                    name: data?.account?.name,
                    existingId: data?.account?.id,
                    id: data?.account?.id,
                })

                // if email exist in response => fill and disabled email field
                if (data?.email) {
                    formik.setFieldValue('email', data.email)
                    setIsEditableEmail(true)
                } else {
                    setIsEditableEmail(false)
                }

                // save company data
                localStorage.setItem(
                    LocalStorageNames.companyInfo,
                    JSON.stringify({
                        name: data?.account?.name,
                        existingId: data?.account?.id,
                        id: data?.account?.id,
                    }),
                )
                localStorage.setItem(LocalStorageNames.role, data?.account?.type)

                // set formik role
                formik.setFieldValue('role', data?.account?.type)
                // set formik company
                formik.setFieldValue('company', {
                    name: data?.account?.name,
                    existingId: data?.account?.id,
                })
            }

            if (!response.ok) {
                console.log(data)
                // @ts-ignore
                toast(data?.message ?? 'Something went wrong', { type: 'error' })
            }
        } catch (error: any) {
            toast(error?.message ?? 'Something went wrong', { type: 'error' })
            console.log(error)
        }
    }

    const formik = useFormik<ISignUpProps>({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            title: '',
            location: '',
            password: '',
            repeatPassword: '',
            agreement: false,
            url: window.location.origin,
            // url: 'https://auth.dev.olimpcorp.com/company/account-type/your-information',
        },
        validate: (values) => {
            const errors: Partial<ISignUpProps> = {}
            if (values.phone && !mobileItiRef.current?.isValidNumber()) {
                errors.phone = 'Invalid phone number'
            }

            return errors
        },
        onSubmit: async (values) => {
            // console.log('val:', values)
            const { repeatPassword, agreement, ...formateData } = values

            const newData = {
                ...formateData,
                role: role,
                phone: formateData.phone,
                company: {
                    ...formateData.company,
                    type: existingCompanyPath ? 'existing' : 'new',
                },
                // if inviteLink set inviteCode
                ...(inviteLink ? { inviteCode: inviteLink } : {}),
                ...(lbInvite ? { lbInviteCode: lbInvite } : {}),
            }

            // console.log('newData:', newData)

            try {
                const response = await fetch(`${URLs.baseApi}/sso/signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newData),
                })

                // get token
                const data = await response.json()
                // console.log('🚀 ~ onSubmit: ~ data:', data)

                if (response.ok) {
                    localStorage.clear()

                    toast(data?.message ?? 'Success', { type: 'success' })

                    if (data?.token && data?.user?.verified) {
                        window.location.href = `${URLs.appHostName}/auth/${data!.token}`
                        return
                    } else if (data.token) {
                        localStorage.setItem(LocalStorageNames.token, data.token)
                        navigateHandler(ROUTES.company.verification)
                        return
                    }
                }

                if (!response.ok) {
                    if (data?.phone) {
                        toast(data.phone, { type: 'error' })
                    }
                    if (data?.message) {
                        toast(data.message, { type: 'error' })
                    }
                }
            } catch (error: any) {
                console.error(error)
                if (error?.phone) {
                    toast(error?.phone, { type: 'error' })
                }
                toast(error?.message ?? 'Something went wrong', { type: 'error' })
            }
        },
        validationSchema: validationSchema,
        validateOnChange: submitted,
    })

    const handleInputChange = (e: any) => {
        formik.handleChange(e)
        formik.setErrors({})
    }

    const handlePhoneChange = (val: string) => {
        formik.setFieldValue('phone', val)
        formik.validateField('phone')
    }

    return (
        <>
            <div className="sign-up__row">
                <InputLabel
                    name="firstName"
                    className="sign-up__input_half sign-up__input_p"
                    value={formik.values.firstName}
                    errorMessage={formik.errors.firstName}
                    onChange={handleInputChange}
                    label="First Name*"
                />
                <InputLabel
                    autoComplete="off"
                    name="lastName"
                    className="sign-up__input_half"
                    value={formik.values.lastName}
                    errorMessage={formik.errors.lastName}
                    onChange={handleInputChange}
                    label="Last Name*"
                />
            </div>
            <div className="sign-up__row">
                <InputLabel
                    autoComplete="off"
                    name="email"
                    disabled={isEditableEmail}
                    className="sign-up__input"
                    value={formik.values.email}
                    errorMessage={formik.errors.email}
                    onChange={handleInputChange}
                    label="Email*"
                />
            </div>
            <div className="sign-up__row">
                <PhoneInput
                    id="user-phone"
                    label="Phone*"
                    name="phone"
                    itiRef={mobileItiRef}
                    className="sign-up__input_phone"
                    errorMessage={formik.touched.phone && formik.errors.phone ? formik.errors.phone : undefined}
                    changeValue={handlePhoneChange}
                    autoComplete="tel"
                />
                <InputLabel
                    name="title"
                    className="sign-up__input_half"
                    value={formik.values.title}
                    onChange={handleInputChange}
                    label="Title"
                    autoComplete="off"
                />
            </div>
            <div className="sign-up__row">
                <GoogleAutocompleteInput
                    // @ts-ignore
                    errorMessage={formik.errors.location?.formattedAddress || formik.errors.location}
                    className="sign-up__googleInput"
                    type="text"
                    autoComplete="off"
                    id="addressSearch"
                    name="location"
                    data-name="location"
                    wrapperClassName={'sign-up__location'}
                    changePlace={(name, val) => {
                        // console.log('val', val)
                        formik.setFieldValue('location', {})
                    }}
                    inputPath="address"
                    label="Your location*"
                    changePlaceFull={(field, value) => {
                        // console.log('changePlaceFull', value)
                        if (value?.formattedAddress) {
                            formik.setFieldValue('location', value)
                            formik.setErrors({})
                        } else {
                            formik.setFieldValue('location', { formattedAddress: '' })
                        }
                    }}
                    placeholder=""
                    onBlur={() => {}}
                />
            </div>
            <div className="sign-up__row">
                <InputLabel
                    name="password"
                    className="sign-up__input"
                    value={formik.values.password}
                    errorMessage={formik.errors.password}
                    onChange={handleInputChange}
                    label="Password*"
                    type="password"
                    autoComplete="off"
                />
            </div>
            <div className="sign-up__row">
                <InputLabel
                    name="repeatPassword"
                    className="sign-up__input"
                    value={formik.values.repeatPassword}
                    errorMessage={formik.errors.repeatPassword}
                    onChange={handleInputChange}
                    label="Repeat Password*"
                    type="password"
                    autoComplete="off"
                />
            </div>
            <div className="sign-up__row">
                <Checkbox
                    name="agreement"
                    onChange={handleInputChange}
                    errorMessage={formik.errors.agreement}
                    value={formik.values.agreement}
                    className="sign-up__agreement"
                    label={
                        <>
                            By creating your account you agree to{' '}
                            <a
                                className="link"
                                href={getOLIMPLandingURL('terms-and-conditions')}
                                target="_blank"
                                rel="noreferrer"
                            >
                                OLIMP&apos;s Terms of Use
                            </a>
                        </>
                    }
                />
            </div>
            <Button
                className="button sign-up__btn sign-up__listBtn"
                type="button"
                disabled={!formik.isValid && !formik.values.agreement}
                types={['blue', 'full']}
                loading={formik.isSubmitting}
                onClick={(e) => {
                    setSubmitted(true)
                    e.preventDefault()
                    formik.handleSubmit()
                }}
            >
                Next
            </Button>
            {(locations.pathname === '/' + ROUTES.existingCompany.yourInformation ||
                locations.pathname === '/' + ROUTES.company.yourInformation) && (
                <div className="sign-up__link">
                    Already have an account? &nbsp;
                    <Link className="link" to={`/login${siteSlug ? '?slug=' + siteSlug : ''}`}>
                        Sign in
                    </Link>
                </div>
            )}
        </>
    )
}
