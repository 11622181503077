export const ROUTES = Object.freeze({
    base: '',
    login: 'login',
    entity: '',
    'forgot-password': 'login/forgot-password',
    'verify-email': 'verify-email',
    invite: 'invite',
    warehouse: 'entity/warehouse',
    company: {
        base: 'company',
        yourInformation: 'company/account-type/your-information',
        selectionPage: 'company/account-type',
        verification: 'company/account-type/your-information/verification',
    },
    existingCompany: {
        base: 'existing-company',
        existing: 'existing-company/existing',
        yourInformation: 'existing-company/existing/your-information',
        verification: 'existing-company/existing/your-information/verification',
        existingAdmin: 'existing-company/existing/admin',
        admin: 'existing-company/admin',
        companyNotFound: 'existing-company/company-not-found',
    },
    loginVerification: 'login/forgot-password/verification',
    verification: 'verification',
    'password-reset': 'password-reset',
    loadboard: {
        base: 'https://app.dev.olimpcorp.com/loadboard',
    },
})
