import React, { memo, useEffect } from 'react'
import { SelectionCard } from '../components/UI/SelectionCard'
import { ROUTES } from '../router'
import { LocalStorageNames, WarehouseType } from 'types'
import { URLs } from 'urls'

interface AccountTypeSelectionPageProps {
    setTitle?: any
    setStep?: any
    navigateHandler: (props: string) => void
    setShowProgress: (param: boolean) => void
}

export const AccountTypeSelectionPage = memo(
    ({ setTitle, navigateHandler, setShowProgress }: AccountTypeSelectionPageProps) => {
        useEffect(() => {
            setTitle('Who are you?')
            setShowProgress(true)
        }, [])

        return (
            <>
                <div style={{ marginBottom: 12 }}>
                    <SelectionCard
                        title={'I need warehousing services'}
                        content={'Select this option if you would like to book a warehouse'}
                        onClick={() => {
                            localStorage.setItem(LocalStorageNames.role, WarehouseType.demand)
                            navigateHandler(ROUTES.company.yourInformation)
                            // window.location.href = `${URLs.wpHostName}/rfq-sign-up`
                        }}
                    />
                </div>
                <SelectionCard
                    title={'I am a warehouse'}
                    content={'Select this option if you would like to add your warehouse to our network.'}
                    onClick={() => {
                        localStorage.setItem(LocalStorageNames.role, WarehouseType.supply)
                        navigateHandler(ROUTES.company.yourInformation)
                    }}
                />
            </>
        )
    },
)
